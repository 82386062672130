import React from "react";
import SEO from '../components/seo';

const CustomOrders = () => (
	<div className="wrapper-main">
	<SEO title="Custom Orders" />
		<iframe
			src="https://docs.google.com/forms/d/e/1FAIpQLSdDLdf2nfalmk9KEPr3141fGp5qrA-yTmxbTNj8ufzd_MFrxg/viewform?embedded=true"
			width="100%"
			height="1653"
			frameborder="0"
			marginheight="0"
			marginwidth="0"
		>
			Cargando...
		</iframe>
	</div>
);

export default CustomOrders;
